import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"

export default function Privacy() {
  return (
    <Layout title="Privacy Policy">
      <div className="abouts segments-page default-container">
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Your privacy is critically important to us.</h2>
                <div className="social-link"></div>
                Pack Trading Group, Inc is located at:
                <br />
                <address>
                  Pack Trading Group, Inc
                  <br />
                  8911 Lakewood Dr. Ste 22B <br />
                  Windsor, CA 95492, USA
                  <br />
                  +1 (707) 234-8221{" "}
                </address>
                <p>
                  It is Pack Trading Group, Inc's policy to respect your privacy
                  regarding any information we may collect while operating our
                  website. This Privacy Policy applies to{" "}
                  <Link name="website" to="/">https://pac-trade.com</Link>{" "}
                  (hereinafter, "us", "we", or "https://pac-trade.com"). We
                  respect your privacy and are committed to protecting
                  personally identifiable information you may provide us through
                  the Website. We have adopted this privacy policy ("Privacy
                  Policy") to explain what information may be collected on our
                  Website, how we use this information, and under what
                  circumstances we may disclose the information to third
                  parties. This Privacy Policy applies only to information we
                  collect through the Website and does not apply to our
                  collection of information from other sources.
                </p>
                <p>
                  This Privacy Policy, together with the Terms and conditions
                  posted on our Website, set forth the general rules and
                  policies governing your use of our Website. Depending on your
                  activities when visiting our Website, you may be required to
                  agree to additional terms and conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Website Visitors</h2>
                <div className="social-link"></div>
                <p>
                  Like most website operators, Pack Trading Group, Inc collects
                  non-personally-identifying information of the sort that web
                  browsers and servers typically make available, such as the
                  browser type, language preference, referring site, and the
                  date and time of each visitor request. Pack Trading Group,
                  Inc's purpose in collecting non-personally identifying
                  information is to better understand how Pack Trading Group,
                  Inc's visitors use its website. From time to time, Pack
                  Trading Group, Inc may release non-personally-identifying
                  information in the aggregate, e.g., by publishing a report on
                  trends in the usage of its website.
                </p>
                <p>
                  Pack Trading Group, Inc also collects potentially
                  personally-identifying information like Internet Protocol (IP)
                  addresses. Pack Trading Group, Inc only discloses IP addresses
                  under the same circumstances that it uses and discloses
                  personally-identifying information as described below.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Gathering of Personally-Identifying Information</h2>
                <div className="social-link"></div>
                <p>
                  Certain visitors to Pack Trading Group, Inc's websites choose
                  to interact with Pack Trading Group, Inc in ways that require
                  Pack Trading Group, Inc to gather personally-identifying
                  information. The amount and type of information that Pack
                  Trading Group, Inc gathers depends on the nature of the
                  interaction. For example, we ask visitors who fill out a
                  contact form at <Link to="/contact/">https://pac-trade.com/contact/</Link> to provide
                  a name and email address.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Security</h2>
                <div className="social-link"></div>
                <p>
                  The security of your Personal Information is important to us,
                  but remember that no method of transmission over the Internet,
                  or method of electronic storage is 100% secure. While we
                  strive to use commercially acceptable means to protect your
                  Personal Information, we cannot guarantee its absolute
                  security.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Links To External Sites</h2>
                <div className="social-link"></div>
                <p>
                  Our Service may contain links to external sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy and terms and conditions of every
                  site you visit.
                </p>
                <p>
                  We have no control over, and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites, products or services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Aggregated Statistics</h2>
                <div className="social-link"></div>
                <p>
                  Pack Trading Group, Inc may collect statistics about the
                  behavior of visitors to its website. Pack Trading Group, Inc
                  may display this information publicly or provide it to others.
                  However, Pack Trading Group, Inc does not disclose your
                  personally-identifying information.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Cookies</h2>
                <div className="social-link"></div>
                <p>
                  To enrich and perfect your online experience, Pack Trading
                  Group, Inc uses "Cookies", similar technologies and services
                  provided by others to display personalized content,
                  appropriate advertising and store your preferences on your
                  computer.
                </p>
                <p>
                  A cookie is a string of information that a website stores on a
                  visitor's computer, and that the visitor's browser provides to
                  the website each time the visitor returns. Pack Trading Group,
                  Inc uses cookies to help Pack Trading Group, Inc identify and
                  track visitors, their usage of https://pac-trade.com, and
                  their website access preferences. Pack Trading Group, Inc
                  visitors who do not wish to have cookies placed on their
                  computers should set their browsers to refuse cookies before
                  using Pack Trading Group, Inc's websites, with the drawback
                  that certain features of Pack Trading Group, Inc's websites
                  may not function properly without the aid of cookies.
                </p>
                <p>
                  By continuing to navigate our website without changing your
                  cookie settings, you hereby acknowledge and agree to Pack
                  Trading Group, Inc's use of cookies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Business Transfers</h2>
                <div className="social-link"></div>
                <p>
                  If Pack Trading Group, Inc, or substantially all of its
                  assets, were acquired, or in the unlikely event that Pack
                  Trading Group, Inc goes out of business or enters bankruptcy,
                  user information would be one of the assets that is
                  transferred or acquired by a third party. You acknowledge that
                  such transfers may occur, and that any acquirer of Pack
                  Trading Group, Inc may continue to use your personal
                  information as set forth in this policy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Privacy Policy Changes</h2>
                <div className="social-link"></div>
                <p>
                  Although most changes are likely to be minor, Pack Trading
                  Group, Inc may change its Privacy Policy from time to time,
                  and in Pack Trading Group, Inc's sole discretion. Pack Trading
                  Group, Inc encourages visitors to frequently check this page
                  for any changes to its Privacy Policy. Your continued use of
                  this site after any change in this Privacy Policy will
                  constitute your acceptance of such change.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Contact Information</h2>
                <div className="social-link"></div>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us via{" "}
                  <a name="Email" href="mailto:privacy@pac-trade.com">email</a> or{" "}
                  <a name="Phone" href="tel:17072348221">phone</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
